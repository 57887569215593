<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <h1>QR Campaign Dashboard</h1>

      <v-spacer></v-spacer>

      <v-btn icon v-if="isAuth" :to="{ name: 'settings' }">
        <v-icon>mdi-cog</v-icon>
      </v-btn>

      <v-btn icon v-if="isAuth" @click="submitLogout">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(["isAuth"]),
  },
  methods: {
    ...mapActions(["logout"]),
    submitLogout() {
      this.logout();

      this.$router.push("/login");
    },
  },
};
</script>
