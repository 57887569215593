<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-btn @click="$router.go(-1)" color="primary">
          <v-icon left dark> mdi-arrow-left </v-icon>
          Back
        </v-btn>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12" md="9">
                <v-text-field
                  v-model="searchComputed"
                  append-icon="mdi-magnify"
                  label="Search Address"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox
                  v-model="filterVisited"
                  label="Hide unvisited"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredAddresses"
            :search="searchComputed"
            :items-per-page="15"
            :loading="loading"
            loading-text="Loading... Please wait"
            class="elevation-1"
          ></v-data-table>

          <v-card-actions>
            <VueJsonToCsv :json-data="jsonData" csv-title="addresses">
              <v-btn :disabled="loading">CSV Download</v-btn>
            </VueJsonToCsv>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import { debounce } from "lodash";

export default {
  props: ["id"],
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      loading: false,
      search: "",
      filterVisited: false,
      addresses: [],
      headers: [
        {
          text: "Property Address",
          align: "start",
          value: "address.property_address",
        },
        {
          text: "Property City",
          align: "start",
          value: "address.property_city",
        },
        {
          text: "Property State",
          align: "start",
          value: "address.property_state",
        },
        {
          text: "Name",
          value: "address.name",
          filterable: false,
        },
        {
          text: "Vistited",
          value: "visited",
          filterable: false,
        },
      ],
    };
  },
  computed: {
    searchComputed: {
      get() {
        return this.search;
      },
      set: debounce(function (newValue) {
        this.search = newValue;
      }, 300),
    },
    filteredAddresses() {
      if (this.filterVisited) {
        return this.addresses.filter((address) => address.visited);
      }

      return this.addresses;
    },
    jsonData() {
      return this.filteredAddresses.map((address) => {
        return {
          "PROPERTY ADDRESS": address.address.property_address,
          "PROPERTY CITY": address.address.property_city,
          "PROPERTY STATE": address.address.property_state,
          "OWNER 1 FIRST NAME": address.address.name.split(" ")[0],
          "OWNER 1 LAST NAME": address.address.name.split(" ")[1],
        };
      });
    },
  },
  async mounted() {
    try {
      this.loading = true;
      const { data } = await this.$http.get("qr_campaign", {
        params: {
          id: this.id,
        },
      });
      this.addresses = data;
      this.loading = false;
    } catch (error) {
      console.error(error.message);
    }
  },
};
</script>
