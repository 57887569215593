<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6">
        <v-alert v-if="errorMessage" type="error">{{ errorMessage }}</v-alert>
        <v-card>
          <v-app-bar flat dark color="primary">
            <v-toolbar-title>Login</v-toolbar-title>
          </v-app-bar>

          <v-card-text>
            <v-form ref="loginForm" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                label="Email/Username"
                :rules="[(v) => !!v || 'Email/Username is required']"
                outlined
                required
              ></v-text-field>

              <v-text-field
                v-model="password"
                type="password"
                label="Password"
                :rules="[(v) => !!v || 'Password is required']"
                outlined
                required
              ></v-text-field>

              <v-btn block color="primary" @click="submitLogin" class="mt-2"
                >Login</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      valid: true,
      email: null,
      password: null,
      errorMessage: "",
    };
  },
  methods: {
    ...mapActions(["login"]),
    async submitLogin() {
      if (!this.$refs.loginForm.validate()) return;

      try {
        await this.login({
          email: this.email,
          password: this.password,
        });

        this.errorMessage = "";

        this.$router.push({ name: "home" });
      } catch (error) {
        console.error(error);
        this.errorMessage = error.response.data.message;
      }
    },
  },
};
</script>

<style></style>
