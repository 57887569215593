<template>
  <v-container>
    <v-row v-if="campaigns.length === 0">
      <v-col>
        <h1 class="text-center">No Campaigns Created</h1>
      </v-col>
    </v-row>
    <v-row
      v-else
      justify="center"
      v-for="campaign in campaigns"
      :key="campaign.id"
    >
      <v-col cols="6">
        <v-card :to="{ name: 'campaign', params: { id: campaign.id } }">
          <v-card-title>
            {{ campaign.name }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      campaigns: [],
    };
  },
  async mounted() {
    try {
      const { data } = await this.$http.get("qr_campaigns");
      this.campaigns = data;
    } catch (error) {
      console.error(error.message);
    }
  },
};
</script>
