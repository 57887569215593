import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token"),
  },
  getters: {
    token(state) {
      return state.token;
    },
    isAuth(state) {
      return !!state.token;
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
  },
  actions: {
    async login({ commit }, payload) {
      const { data } = await this._vm.$http.post("login", {
        username: payload.email,
        password: payload.password,
      });

      this._vm.$http.defaults.headers.common[
        "Authorization"
      ] = `Token ${data.token}`;

      localStorage.setItem("token", data.token);
      commit("setToken", data.token);
    },
    logout({ commit }) {
      localStorage.removeItem("token");
      commit("setToken", null);
    },
  },
  modules: {},
});
